import { useRef, useState } from "react";
import "./App.css";
import logo from "./assets/logo.gif";
import pixel from "./assets/pixel.png";
import hero from "./assets/Hero.png";
import img1 from "./assets/1.png";
import img2 from "./assets/2.png";
import img3 from "./assets/3.png";
import img4 from "./assets/4.png";
import img5 from "./assets/5.png";
import img6 from "./assets/6.png";
import img7 from "./assets/7.png";
import img8 from "./assets/8.png";
import img9 from "./assets/9.png";
import img10 from "./assets/10.png";
import img11 from "./assets/11.png";
import img12 from "./assets/12.png";
import img13 from "./assets/13.png";
import img14 from "./assets/14.png";
import img15 from "./assets/15.png";
import img16 from "./assets/16.png";
import img17 from "./assets/17.png";
import img18 from "./assets/18.png";
import discord from "./assets/discord.png";
import twitter from "./assets/Twitter.png";
import subber from "./assets/subber.jpg";
import wallet from "./assets/wallet.jpg";
import knock from "./assets/img.png";
import ReCAPTCHA from "react-google-recaptcha";
function App() {
  const captchaRef = useRef(null);
  const [isBot, setBot] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const onSubmit = async () => {
    const token = captchaRef.current.getValue();
    if (name && email && message && subject) {
      const data = {
        name,
        email,
        subject,
        message,
      };
      if (token) {
        await fetch(`https://crypto-oridinal.vercel.app/mint/mail`, {
          method: "POST",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((json) =>
            alert("Thank you for your query, we will contact you soon")
          );
      } else {
        setBot(true);
      }
    } else {
      alert("Enter details correctly.");
    }
  };
  var lastScrollTop = 0;
  var marginRight = 0;
  var marginLeft = 0;

  function moveCollection() {
    var ypos2 = window.pageYOffset;
    // const elem = document
    //   .getElementById("collection_items")
    //   .getBoundingClientRect();
    // if (elem.y < elem.height && elem.y > -1 * elem.height) {
    if (ypos2 < lastScrollTop) {
      marginRight += 2;
      marginLeft += 2;
      var first = document.querySelector(".firstline");
      var third = document.querySelector(".secondline");
      if (first !== null && third !== null) {
        document.querySelector(".firstline").style.marginRight =
          marginRight + "px";
        document.querySelector(".secondline").style.marginLeft =
          marginLeft + "px";
      }
    } else {
      marginRight -= 2;
      marginLeft -= 2;
      first = document.querySelector(".firstline");
      third = document.querySelector(".secondline");
      if (first !== null && third !== null) {
        document.querySelector(".firstline").style.marginRight =
          marginRight + "px";
        document.querySelector(".secondline").style.marginLeft =
          marginLeft + "px";
        // }
      }
    }
    lastScrollTop = ypos2;
  }

  window.onscroll = moveCollection;

  return (
    <div className="App">
      <div className="hero_container">
        <div className="hero_logo_view">
          <img src={hero} className="hero_img" alt="hero" />
          <img src={knock} className="knock_img" alt="knock" />
        </div>
      </div>
      <div className="logo_container">
        <img src={logo} alt="logo" width="120px" className="logo_img" />
      </div>
      <div className="collection_text">THE COLLECTION</div>
      <div className="collection_items" id="collection_items">
        <div className="collection_items_first firstline">
          <img src={img1} alt="logo" className="collect_img" />
          <img src={img2} alt="logo" className="collect_img" />
          <img src={img3} alt="logo" className="collect_img" />
          <img src={img4} alt="logo" className="collect_img" />
          <img src={img5} alt="logo" className="collect_img" />
          <img src={img6} alt="logo" className="collect_img" />
          <img src={img7} alt="logo" className="collect_img" />
          <img src={img8} alt="logo" className="collect_img" />
          <img src={img9} alt="logo" className="collect_img" />
        </div>
        <div className="collection_items_second secondline">
          <img src={img10} alt="logo" className="collect_img" />
          <img src={img11} alt="logo" className="collect_img" />
          <img src={img12} alt="logo" className="collect_img" />
          <img src={img13} alt="logo" className="collect_img" />
          <img src={img14} alt="logo" className="collect_img" />
          <img src={img15} alt="logo" className="collect_img" />
          <img src={img16} alt="logo" className="collect_img" />
          <img src={img17} alt="logo" className="collect_img" />
          <img src={img18} alt="logo" className="collect_img" />
        </div>
      </div>
      <a
        className="collection_text_ref"
        href="https://magiceden.io/ordinals/marketplace/ordinal-insiders"
        target="_blank"
        without
        rel="noreferrer"
      >
        <div className="collection_text">VIEW MORE</div>
      </a>
      <div className="collection_text">MARKETPLACES</div>
      <div className="collection_view">
        <a
          href="https://magiceden.io/ordinals/marketplace/ordinal-insiders"
          target="_blank"
          without
          rel="noreferrer"
        >
          <div className="collection_item">
            <img
              src={pixel}
              alt="pixel"
              width={150}
              height={150}
              className="view_img"
            />
            <div className="description">MAGIC EDEN ORDINALS</div>
          </div>
        </a>
        <a
          href="https://ordinalswallet.com/collection/ordinal-insiders"
          target="_blank"
          without
          rel="noreferrer"
        >
          <div className="collection_item">
            <img
              src={wallet}
              alt="pixel"
              width={150}
              height={150}
              className="view_img"
            />
            <div className="description">ORDINALS WALLET</div>
          </div>
        </a>
      </div>
      <div className="collection_text">SOCIALS</div>
      <div className="collection_view">
        <a
          href="https://discord.gg/jwTy8rWgR7"
          target="_blank"
          without
          rel="noreferrer"
        >
          <div className="collection_item">
            <img
              src={discord}
              alt="pixel"
              width="150px"
              height="150px"
              className="view_img"
            />
            <div className="description">DISCORD</div>
          </div>
        </a>
        <a
          href="https://twitter.com/OrdinalInsiders"
          target="_blank"
          without
          rel="noreferrer"
        >
          <div className="collection_item">
            <img
              src={twitter}
              alt="pixel"
              width="150px"
              height="150px"
              className="view_img"
            />
            <div className="description">TWITTER</div>
          </div>
        </a>
        <a
          href="https://magiceden.io/ordinals/marketplace/ordinal-insiders"
          target="_blank"
          without
          rel="noreferrer"
        >
          <div className="collection_item">
            <img
              src={subber}
              alt="pixel"
              width="200px"
              height="150px"
              className="view_img"
            />
            <div className="description">SUBBER</div>
          </div>
        </a>
      </div>
      <div className="collection_text" style={{ marginTop: "140px" }}>
        CONTACT US
      </div>
      <div className="form">
        <div className="contact_form">
          <div className="form_container">
            <input
              type="text"
              id="name"
              placeholder="FULL NAME"
              className="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required={true}
            />
            <input
              type="text"
              id="subject"
              placeholder="SUBJECT"
              className="subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required={true}
            />
          </div>
          <div className="form_container">
            <input
              type="text"
              id="email"
              placeholder="EMAIL ADDRESS"
              className="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required={true}
            />
          </div>
          <div className="form_container">
            <textarea
              type="text"
              id="message"
              placeholder="MESSAGE"
              className="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required={true}
            />
          </div>
          <div className="captcha">
            <ReCAPTCHA
              sitekey="6Ld3B5clAAAAAPrUni896jIhyaYEbYiEdr56j6HN"
              style={{
                display: "inline-block",
                height: "50px",
                marginTop: "20px",
              }}
              size="normal"
              theme="light"
              ref={captchaRef}
            />
            {isBot ? (
              <span className="err-recaptcha">
                This is ReCAPTCHA for human testing.
              </span>
            ) : (
              ""
            )}
          </div>
          <div>
            <button className="submit" onClick={onSubmit}>
              SUBMIT
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
